.Feedback {
  
    &__button {
        position: absolute;
        left: auto;
        top: auto;
        right: 0%;
        bottom: 0%;
        z-index: 100;
        padding-right: 10vw;
        padding-bottom: 5vh;
    }

    &__form {
        position: absolute;
        width: 320px;
        left: auto;
        top: auto;
        right: 10px;
        bottom: 10px;
        z-index: 101;
    }

    &__message {
        background-color: white;
        border: 1px solid black;
    }

    &__textarea {
        height: 400px;
    }

    &__button {
        padding: 10px;
        margin-right: 4px;
        margin-bottom: 4px;
    }
  }
  