.navbar-logo {
    font-size: 20pt;
}

.streak {
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out 0.3s;
}
.streak-on {
    background-color: lightsalmon;
}
.pro-badge {
    background-color: #444;
    color: white;
    padding: 0.25rem;
    border-radius: 0.25rem;
}