.FirePlace {
    $black:#111217;
    $brownl:#70392F;
    $brownd:#612E25;
    $yellow:#FFDC01;
    $orange:#FDAC01;
    $red:#F73B01;
    $animationtime:2s;
    width: 2rem;
    height: 2rem;

       .fmoji {
           position: relative;
           left: 10px;
           top: -4px;
           animation: appear $animationtime ease-in 1;
       }
    
       .fire {
          position: absolute;
          top:50%;
          left: 45px;
          transform:translate(-50%,-25%);
          height:2rem;
          width:2rem;
          .flames[anim="yes"] {
              .flame {
                &:nth-child(2n + 1) {
                    animation: flameodd $animationtime ease-in infinite;
                 }
                 &:nth-child(2n) {
                    animation: flameeven $animationtime ease-in infinite;
                 }
                 &:nth-child(1) {
                    animation-delay:0s;
                 }
                 &:nth-child(2) {
                    animation-delay:$animationtime/4;
                 }
                 &:nth-child(3) {
                    animation-delay:$animationtime/4 * 2;
                 }
                 &:nth-child(4) {
                    animation-delay:$animationtime/4 * 3;
                 } 
              }
          }
          .flames {
             position: absolute;
             bottom:40%;
             left:50%;
             width:60%;
             height:60%;
             //background-color:$red;
             transform:translateX(-50%)rotate(45deg);
             .flame {
                position: absolute;
                right:0%;
                bottom:0%;
                width:0%;
                height:0%;
                background-color:$yellow;
                border-radius:5px;
                &:nth-child(2n + 1) {
                   animation: flameodd $animationtime ease-in;
                }
                &:nth-child(2n) {
                   animation: flameeven $animationtime ease-in;
                }
                &:nth-child(1) {
                   animation-delay:0s;
                }
                &:nth-child(2) {
                   animation-delay:$animationtime/4;
                }
                &:nth-child(3) {
                   animation-delay:$animationtime/4 * 2;
                }
                &:nth-child(4) {
                   animation-delay:$animationtime/4 * 3;
                }
             }
          }
          .logs {
             position: absolute;
             bottom:25%;
             left:50%;
             transform:translateX(-50%);
             width:100%;
             height:15%;
             &:before, &:after {
                position: absolute;
                content: '';
                top:50%;
                left:50%;
                transform:translate(-50%,-50%) rotate(20deg);
                height:100%;
                width:100%;
                border-radius:1vw;
                background-color:$brownl;
             }
             &:before {
                transform:translate(-50%,-50%) rotate(-20deg);
                background-color:$brownd;
             }
          }
       }
    
    @keyframes flameodd {
       0%, 100% {
          width:0%;
          height:0%;
       }
       25% {
          width:100%;
          height:100%;
       }
       0% {
          background-color:$yellow;
          z-index:1000000;
       }
       40% {
          background-color:$orange;
          z-index:1000000;
       }
       100% {
          background-color:$red;
          z-index:-10;
       }
       0% {
          right:0%;
          bottom:0%;
       }
       25% {
          right:1%;
          bottom:2%;
       }
       100% {
          right:150%;
          bottom:170%;
       }
    }
    
    @keyframes flameeven {
       0%, 100% {
          width:0%;
          height:0%;
       }
       25% {
          width:100%;
          height:100%;
       }
       0% {
          background-color:$yellow;
          z-index:1000000;
       }
       40% {
          background-color:$orange;
          z-index:1000000;
       }
       100% {
          background-color:$red;
          z-index:-10;
       }
       0% {
          right:0%;
          bottom:0%;
       }
       25% {
          right:2%;
          bottom:1%;
       }
       100% {
          right:170%;
          bottom:150%;
       }

    }

    @keyframes appear {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}