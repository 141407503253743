.DashboardSection {

  &__paid-message {
    position: relative;
    left: -2rem;
  }

  &__image {
    margin: 0 auto;
    max-width: 370px;
  }

  &__mobileSpacer {
    height: 60px;
  }

  &__main {
    background-size: 1rem 1rem;
    background-image: radial-gradient(circle, #eee 1px, rgba(0, 0, 0, 0) 1px);
    padding: 0;//1rem;
    margin: 0;
    height: 200px;
  }

  &__calendar {
    position: relative;
    top: -4rem;
  }

  &__locField {
    padding-bottom: 1rem;
  }

  &__bigday {
    font-size: 10rem;
    padding:0;
    margin-bottom:0;
    vertical-align: top;
    line-height: 1.0;
  }

  &__pagetitle {
    font-size: 4rem;
    padding:0;
    margin-bottom:0;
    vertical-align: top;
    line-height: 1.0;
  }

  &__dayofweek {
    position: relative;
    top: 8px;
    text-transform: uppercase;
    font-size: 2.5rem;
  }

  &__monthandyear {
    font-size: 1.5rem;
  }

  &__holiday {
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  &__prevDay {
    position: relative;
    left: 220px;
    height: 4rem;
    width: 4rem;
  }
  &__nextDay {
    position: relative;
    left: 220px;
    height: 4rem;
    width: 4rem;
  }
  &__mobButtons {
    display: flex;
  }

  &__section {
    margin: 0;
    padding-top: 0;
    top: -60px;
  }

  &__sunspan {
    position: absolute;
  }

  &__editor {
    h1 {
      font-size: 24pt;
    }
    h2 {
      font-size: 18pt;
    }
    h3, h4 {
      font-size: 14pt;
    }
    ul {
      list-style: circle;
    }

    .image img {
      display: initial;
      width: auto;
    }
  }

}

@media (max-width: 1024px) {
  .DashboardSection {
    &__paid-message {
      position: relative;
      left: -2rem;
      top: 3rem;
      width: 120%;
    }

    &__dayofweek {
      position: relative;
      top: 8px;
      text-transform: uppercase;
      font-size: 2rem;
    }
  }
}

.hasEntry {
  font-weight: bolder;
}